import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { auth } from 'firebase'

const Wrapper = styled.div`
    min-height: 100vh;
    height: 100%;
    min-width: 13vw;
    max-width: 13vw;
    padding: 10vh 20px;
    background-color: #4b4f77;
    position: sticky;
    left: 0;
    top: 0;
    display: flex; 
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @media(max-width: 800px){
        min-width: 10vw;
        max-width: 10vw;
        padding: 10vh 0px;
    }
`;

const MenuItem = styled(NavLink)`
    color: #F7F7F7;
    font-family: ${props => props.theme.font3};
    @media(max-width: 800px){
        transform: rotate(-90deg);
    }
    :hover{
        color: #f7c12e;
    }
`;

const Logout = styled.div`
    position: absolute;
    bottom: 50px;
    color: #F7F7F7;
    cursor: pointer;
    font-family: ${props => props.theme.font3};
    @media(max-width: 800px){
        transform: rotate(-90deg);
    }
    :hover{
        color: #f7c12e;
    }
`;

export default () => (
    <Wrapper>
        <MenuItem to="/enquiries" activeStyle={{color: '#f7c12e'}}>
            Enquiries
        </MenuItem>
        <MenuItem to="/careers" activeStyle={{color: '#f7c12e'}}>
            Careers
        </MenuItem>
        <Logout onClick={() => {
            auth().signOut()
        }}>
            Signout
        </Logout>
    </Wrapper>
)