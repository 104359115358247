import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
display: none;
    position: sticky;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgb(0,0,0, 0.5);
`;

const Container = styled.div`
    position: absolute;
    top: 50%;
    overflow: hidden;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    background-color: #fff;
    border-radius: 20px;
    @media(max-width: 800px){
        width: 80vw;
    }
`;

const Title = styled.div`
    padding: 10px;
    font-size: 20px;
    font-weight: 800;
    font-family: ${props => props.theme.font4};
    margin: 10px 0px;
    text-align: center;
`;

const Message = styled.div`
    text-align: center;
    font-family: ${props => props.theme.font3};
    font-size: 16;
    padding: 10px;
    border-style: solid;
    border-bottom-width: 1px;
    border-color: #f7f7f7;
    padding-bottom: 20px;
`;

const Button = styled.div`
    width: 100%;
    padding: 15px;
    cursor: pointer;
    text-align: center;
        border-style: solid;
    border-right-width: 1px;
    border-color: #f7f7f7;
    font-family: ${props => props.theme.font3};
    :hover{
        background-color: #f7f7f7;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;


export default ({title, message, itemName, id, handleCancel, handleConfirm}) => {

    return(
        <Wrapper id={id}>
            <Container>
                <Title>
                    {title}
                </Title>
                <Message>
                    {message}
                </Message>
                <ButtonWrapper>
                    <Button onClick={() => {
                        handleCancel("", "")
                    }}>
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        handleConfirm()
                    }}>
                        Delete
                    </Button>
                </ButtonWrapper>
            </Container>
        </Wrapper>
    )
}