import React from 'react';
import { Route, Switch } from "react-router-dom";
import Login from '../routes/Login'
import Dashboard from '../routes/Dashboard';
import Products from '../routes/Products';
import Orders from '../routes/Orders'
import Menu from './Menu';

const PublicRoutes  = () => (
    <Switch>
        <Route exact path="/" component={Login} />
        <Route component={Login} />
    </Switch>
)

const LoggedInRoutes = () => (
    <>
        <Menu />
        <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/enquiries" component={Orders} />
            <Route exact path="/careers" component={Products} />
        </Switch>
    </>
)

const AppRouter = ({isAuth}) => (
    <Switch>
        {isAuth ? <LoggedInRoutes/> : <PublicRoutes />}
    </Switch>
)

export default AppRouter;