import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCKpOv6dRB3a7Uqj9cXAInJRGkAwcisHQs",
  authDomain: "ywsoo-5b391.firebaseapp.com",
  databaseURL: "https://ywsoo-5b391.firebaseio.com",
  projectId: "ywsoo-5b391",
  storageBucket: "ywsoo-5b391.appspot.com",
  messagingSenderId: "1056441401046",
  appId: "1:1056441401046:web:389da4c7807eb783c65377",
  measurementId: "G-TZP8J3RB60"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));