import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
    ${reset};
    * {
        box-sizing: border-box;
    }


    body {
        background-color:#F2F2F2;
    }
    a {
        text-decoration:none;
    }
    input:focus{
        outline: none;
    }
`;