import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
width: 100%;
max-width: 280px;
margin: 10px;
background-color: white;
border-radius: 12px;
padding: 20px 12px;
`;

const NameWrapper = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

const Name = styled.h1`
    font-family: ${props => props.theme.font3};
    font-size: 16px;
    margin-bottom: 8px;
`;

const Delete = styled.div`
    padding: 12px;
    color: red;
    cursor: pointer;
    :hover{
        background-color: #f1f3f6;
    }
`
const ViewPdf = styled.div`
    padding: 12px;
    color: blue;
    cursor: pointer;
    :hover{
        background-color: #f1f3f6;
    }
`

const Text = styled.p`
    text-align: center;
    font-family: ${props => props.theme.font4};
`

export default ({deleteResume, name,id,expectedSalary, email,spmuec, doa, dob, contact, address, academic, pdfUrl}) => (
 
    <Wrapper>
        <NameWrapper>
            <Name>
                Name: {name}
            </Name>
            <Name>
                Contact: {contact}
            </Name>
            <Name>
                Email: {email}
            </Name>
            <Name>
                Date of birth: {dob}
            </Name>
            <Name>
                Address: {address}
            </Name>
            <Name>
                Expected Salary: RM{expectedSalary}
            </Name>
            <Name>
                Academic Result: {academic}
            </Name>
            <Name>
                SPM/UEC: {spmuec}
            </Name>
            <Name>
                Date of Availability: {doa}
            </Name>
        </NameWrapper>
        <Delete onClick={() => {
            deleteResume(id, name)
        }}>
            <Text>Delete</Text>
        </Delete>
        <ViewPdf onClick={() => {
            window.open(pdfUrl)
        }}>
            <Text>View Resume</Text>
        </ViewPdf>
    </Wrapper>
)