import React, { Component } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';

const Wrapper = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 15px;
    display: flex;
    padding: 15px;
    flex-direction: row;
    margin: 10px 0px;
    @media(max-width: 800px){
        flex-direction: column;
    }
`;

const Contact = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;

const Title = styled.div`
font-weight: 800;
font-size: 18px
font-family: ${props => props.theme.font4};
margin-bottom: 5px;
`;

const InfoRow = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 10px;
`;

const Subtitle = styled.span`
    font-size: 14px;
    line-height: 18px;
    font-family: ${props => props.theme.font3};
`;

const Text = styled.div`
    font-size: 14px;
    line-height: 18px;
    max-width: 180px;
    font-family: ${props => props.theme.font3};
    word-wrap: break-word;
`;


const Delete = styled.p`
    color: red;
    font-family: ${props => props.theme.font1};
    cursor: pointer;
    :hover{
        color: maroon;
    }
`
export default class Incomplete extends Component{

    constructor(props){
        super(props);
        this.state={
            items: []
        }
    }

    componentDidMount(){
    }
    

    render(){
    return(
        <Wrapper>
            <Contact>
                <Title>
                    Contact Info
                </Title>
                <InfoRow>
                    <Subtitle>
                        Name: &nbsp;
                    </Subtitle>
                    <Text>
                        {this.props.name}
                    </Text>
                </InfoRow>
                <InfoRow>
                    <Subtitle>
                        Contact No: &nbsp;
                    </Subtitle>
                    <Text>
                        {this.props.number}
                    </Text>
                </InfoRow>
                <InfoRow>
                    <Subtitle>
                        Email: &nbsp;
                    </Subtitle>
                    <Text>
                        {this.props.email}
                    </Text>        
                </InfoRow>
                <InfoRow>
                    <Subtitle>
                        Description: &nbsp;
                    </Subtitle>
                    <Text>
                        {this.props.description}
                    </Text>        
                </InfoRow>
            </Contact>
            <Delete onClick={() => {
                database().ref('enquiries').child(this.props.id).set(null)
            }}>Delete</Delete>
        </Wrapper>
    )
}}