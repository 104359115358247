import React, { useState,useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Theme from '../styles/Theme';
import {HashRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { auth } from 'firebase'
import { toast } from 'react-toastify';

toast.configure();
const Wrapper = styled.div`
`;

const FooterWrapper = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
`;

const Copyright = styled.span`
  display: inline;
  justify-content: center;
  font-family: 'SF Pro Text','Myriad Set Pro','SF Pro Icons','Apple Legacy Chevron','Helvetica Neue','Helvetica','Arial',sans-serif;
  font-weight: 400;
  line-height: 16px;
  color: #888888;
  font-size: 12px;
  text-align: center;
`;

function App() {
  
  const [ isAuth, setAuth ] = useState(false)

  function getAuthStatus() {
    auth().onAuthStateChanged(user => {
      if(user){
        setAuth(true)
      }else{
        setAuth(false)
      }
    })
    
  }

  useEffect(() => {
    getAuthStatus()
  })

  return (
    <ThemeProvider theme={Theme}>
      <Wrapper>
        <GlobalStyles />
        <Router>
          <Routes isAuth={isAuth}/>
          <FooterWrapper>
            <Copyright>Copyright &copy; {new Date().getFullYear()} Y W Soo & Co. All rights reserved.</Copyright>
          </FooterWrapper>
        </Router>
      </Wrapper>
    </ThemeProvider>
  );
}

export default App;
