import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 30px;
    font-family: ${props => props.theme.font4};
    font-weight: 800;
    font-size: 24px;
`;

export default ({Pagetitle}) => (
    <Wrapper>
        {Pagetitle}
    </Wrapper>
)