import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '../components/Input';
import Button from '../components/Button';
import {auth as firebase } from 'firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
`;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    padding: 30px 20px;
    border-radius: 10px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    @media(max-width: 1050px){
        width: 50vw;
    }
    @media(max-width: 800px){
        width: 80vw;
    }
`;

const FormContainer = styled.form`
    display: flex;
    justify-content: space-between;
    height: 160px;
    flex-direction: column;
`;

const Title = styled.h1`
    font-family: ${props => props.theme.font4};
    font-size: 22px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 800;
`;

function handleSubmit(e, email, password){
    e.preventDefault();
    firebase().signInWithEmailAndPassword(email, password).then(userInfo => {localStorage.setItem("uid", userInfo.user.uid); window.location.reload()}).catch(error => {
        toast.error(error.message, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
            });
    })
}

export default () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    return(
    <Wrapper>
        <Container>
            <Title>Sign In</Title>
            <FormContainer onSubmit={e => handleSubmit(e, email, password)}>
                <Input placeholder="E-mail (required)" required={true} value={email} onChange={e => {
                    setEmail(e.target.value)
                }}/>
                <Input placeholder="Password (required)" type="password" required={true} value={password} onChange={e => {
                    setPassword(e.target.value)
                }}/>
                <Button value="SIGN IN"/>
            </FormContainer>
        </Container>
    </Wrapper>
)}