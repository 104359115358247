import React, {useState} from 'react';
import styled from 'styled-components';
import Input from './Input';
import Button from './Button';
import GreyButton from './GreyButton';
import * as firebase from 'firebase';
import { toast } from 'react-toastify';

const PageWrapper = styled.div`
display: none;
    background-color: rgb(0, 0, 0, 0.5);
    min-height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2
`;

const Wrapper = styled.div`
position: absolute;
width: 50vw;
padding: 15px;
top: 50%;
left: 50%;
z-index: 3;
background-color: white;
border-radius: 20px;
transform: translate(-50%, -50%);
@media(max-width: 800px){
    width:85vw;
}
`;

const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    height: 220px;
    justify-content: space-between;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 800;
    font-family: ${props => props.theme.font4};
    margin: 15px 0px;
    text-align: center;
`;

export default () => {

    const [name, setName] = useState("")

    function submitProduct(e){
        e.preventDefault();
        let name = document.getElementById("productName").value;
        let img = document.getElementById("productImageUploader").files[0];
        let productsRef= firebase.database().ref('products');
        let newProductRef = productsRef.push();
        let storageRef = firebase.storage().ref('products/' + newProductRef.key + '/product.png')
        var metadata = {
            contentType: 'image/png',
          };
        let saveImg = storageRef.put(img, metadata)
        let imgtoast = null
        saveImg.on('state_changed', function(snapshot){
            var imgProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if(imgtoast === null){
                imgtoast = toast("Submitting Image: " + imgProgress.toFixed(2) + "%", {progress: imgProgress, position: 'top-right'})
            }else{
                if(imgProgress === 100){
                    toast.update(imgtoast, {progress: imgProgress, autoClose: true})
                    imgtoast = null
                }else{
                toast.update(imgtoast, {render: "Submitting Image: " + imgProgress.toFixed(1) + "%" })
                }}
        }, error => {
            toast.error(error.message, {delay: 2000, position: 'top-right'})
        }, () => {
            saveImg.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                newProductRef.set({
                    name: name,
                    id: newProductRef.key,
                    imgUlr: downloadURL
                }).then(() => {
                    toast.success("Product added!", {delay: 2500, position: "top-right"})
                })
                
              });
        })
    }

    return(
        <PageWrapper id="productForm">
        <Wrapper>
            <Title>
                New Product
            </Title>
            <FormWrapper onSubmit={e=> submitProduct(e)}>
                <Input id="productName" placeholder="Product Name" value={name} onChange={e => setName(e.target.value)}/>
                <Input id="productImageUploader" type="file" placeholder="Product Image"/>
                <Button value="Add Product" />
                <GreyButton value="Cancel" onClick={e => {
                    e.preventDefault();
                    document.getElementById("productForm").style.display = "none";
                }}/>
            </FormWrapper>
        </Wrapper>
        </PageWrapper>
    )
}