import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.input`
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: #dbdbdb;
    background-color: #F7F7F7;
    padding: 10px 20px;
    height: 45px;
    font-size: 14px;
    :hover{
        border-color: ${props => props.theme.blue}
    }
`;

export default ({id, placeholder, value, onChange, type="input", required=false}) => (
    <Wrapper id={id} placeholder={placeholder} required={required} type={type} onChange={onChange} value={value}/>
)