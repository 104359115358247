import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
    width: 100%;
    border-style: none;
    border-radius: 15px;
    border-color: #dbdbdb;
    background-color: ${props => props.theme.grey};
    padding: 10px 20px;
    height: 45px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    :hover{
        opacity: 0.8;
    }
`;

export default ({onClick, value}) => (
    <Wrapper type="submit" onClick={onClick} >
        {value}
    </Wrapper>
)