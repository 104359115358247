import React, { Component } from 'react';
import styled from 'styled-components';
import PageTitle from '../components/PageTitle';
import ProductForm from '../components/ProductForm';
import Product from '../components/products/Product';
import ConfirmDelete from '../components/ConfirmDelete';
import { database } from 'firebase';

const Wrapper = styled.div`
    min-height: 100vh;
    padding: 20px;
    padding-left: 13vw;
    margin-top: -100vh;
    @media(max-width: 800px){
        padding-left: 10vw;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const PlusButton = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #70e49c;
    border-radius: 15px;
    cursor: pointer;
    align-items: center;
    :hover{
        opacity: 0.7;
    }
`;

const PlusText = styled.div`
    color: white;
    font-family: ${props => props.theme.font3};
    font-size: 14px;
    margin-left: 10px;
    @media(max-width: 650px){
        display: none;
    }
`;


const Plus = styled.div`
    content: '';
    display: block;
    height:3px;
    width: 15px;
    border-radius: 5px;
    background-color: white;
    ::after{
        content: '';
        display: block;
        border-radius: 5px;
        background-color: white;
        height:3px;
        width: 15px;
        transform: rotate(90deg);
    }
`;

const PlusWrapper = styled.div`
    padding: 30px;
`;

const ContentWrapper = styled.div`
    padding: 30px ;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export default class Products extends Component{

    constructor(props){
        super(props)
        this.state={
            message: "Loading...",
            deleteMessage: "",
            deleteTitle: "Delete Item"
        }
    }

    componentDidMount(){
        this.getData();
    }

    handleDelete = (id, name) => {
        this.setState({
            deleteMessage: "Confirm delete " + name + "?",
            deleteTitle: "Delete " + name,
            deleteId: id
        })
        document.getElementById("deleteProductForm").style.display ="block"
    }

    getData = () => {
        var productsRef = database().ref('resume');
        productsRef.on('value', snapshot => {
            if(snapshot.val()){
                let dataArray = [];
                snapshot.forEach(childSnapshot => {
                    let data = childSnapshot.val();
                    dataArray.push(data)
                    this.setState({
                        products: dataArray
                    })
                })
            }else{
                this.setState({
                    products: false,
                    message: "No job application yet."
                })
            }
        })
    }
    
    confirmDelete = async () => {
        await database().ref('resume/' + this.state.deleteId).remove()
        document.getElementById("deleteProductForm").style.display ="none"
    }

    handleCancelDelete = (msg) => {
        this.setState({
            deleteMessage: msg,
            deleteTitle: "",
            deleteId: ""
        })
        document.getElementById("deleteProductForm").style.display ="none"
    }

    render(){
    return(
        <Wrapper>
            <ProductForm />
            <ConfirmDelete id="deleteProductForm" handleConfirm={this.confirmDelete} handleCancel={this.handleCancelDelete} title={this.state.deleteTitle} message={this.state.deleteMessage}/>
            <Header>
                <PageTitle Pagetitle="Job Application"/>
            </Header>
            <ContentWrapper>
                {this.state.products ? this.state.products.map((product, index) => {
                    return <Product name={product.name} deleteResume={this.handleDelete} spmuec={product.spmuec} key={index} id={product.id} email={product.email} expectedSalary={product.expectedSalary} doa={product.doa} dob={product.dob} contact={product.contact} address={product.address} academic={product.academic} pdfUrl={product.pdfUrl}/>
                }) : this.state.message}
            </ContentWrapper>
        </Wrapper>  
    )}
}