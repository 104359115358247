import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    min-height: 100vh;
    margin-top: -100vh;
`;

export default () => {
    return(
        <Wrapper>

        </Wrapper>
    )
}