import React, {Component} from 'react';
import styled from 'styled-components';
import PageTitle from '../components/PageTitle';
import Incomplete from '../components/orders/Incomplete';
import { database } from 'firebase';

const Wrapper = styled.div`
    min-height: 100vh;
    padding: 20px;
    padding-left: 13vw;
    margin-top: -100vh;
    @media(max-width: 800px){
        padding-left: 10vw;
    }
`;

const OrdersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width:calc(100% - 15px);
    margin-left: 15px;
`;

export default class Orders extends Component{

    constructor(props){
        super(props);
        this.state={
            message: "Loading..",
            orders: false
        }
    }


    getData = () => {
        let ordersRef = database().ref('enquiries');
        ordersRef.on('value', data => {
            if(data.val()){
                let array = [];
                data.forEach( childData => {
                    let data = childData.val()
                    data.id = childData.key
                    array.unshift(data)
                })
                this.setState({
                    orders: array
                })
            }else{
                this.setState({
                    orders: false,
                    message: "No enquiries yet."
                })
            }
        })
        

    }

    componentDidMount(){
        this.getData();
    }

    render(){
    return(
        <Wrapper>
            <PageTitle Pagetitle={`Enquiries ${this.state.orders.length ? "(" + this.state.orders.length + ")" : ""}`}/>
            <OrdersWrapper>
            {this.state.orders ? this.state.orders.map((order, index) => {
                return <Incomplete name={order.name} description={order.enquiries} number={order.phone} email={order.email} id={order.id} key={index} />
            }) : this.state.message}
            </OrdersWrapper>
        </Wrapper>
    )
}}